.about-section-1 {
  backdrop-filter: grayscale(30%);
  background-image: linear-gradient(#ffd7fe, rgb(19, 55, 199));
}
li{
  padding-right: 15px;
}

body {
  overflow-x: hidden;
}

.intro-text {
  animation: waviy 3s infinite;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes waviy {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

