.project-section-1 {
  background-image: linear-gradient(rgb(72, 199, 148), #24075a);
}
.subtitle {
  font-size: 20px;
  color: white;
  text-align: center;
}
p{
  color: black;
}

.cards-section {
  display: grid;
  grid-template-columns: fit-content(60%) fit-content(60%);
  gap: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
  justify-items: center;
}
.inner-cards {
  display: grid;
  grid-template-rows: repeat(var(--rowNum), var(--gridHeight));
  grid-template-columns: repeat(var(--colNum), auto);
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-items: center;
  flex-wrap: wrap;
  align-items: center;
}
div.card {
  position: relative;
  background-color: rgba(2, 0, 4, 0.305);
  height: auto;
}
