form {
  width: 100%;
  text-align: center;
}
.contact-section {
  background-image: linear-gradient(#d1b411, #54ebc3);
}
.form-group {
  margin: 30px;
}

input,
textarea {
  width: 70%;
  padding: 10px;
  color: black;
  background-color: white;
  border: none;
  border-radius: 8px;
}
textarea {
  height: fit-content;
}

button[type="submit"] {
  background-color: rgb(69, 66, 229);
  color: white;
  box-shadow: black;
  font-size: 20px;
  outline: none;
  padding: 1rem 4rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  align-self: center;
  text-align: center;
  display: inline;
  margin: 20px;
  animation: wiggle 2s linear infinite;
}

@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
