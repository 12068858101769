.tech-skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}
img {
  padding-bottom: 10px;
  align-self: center;
  justify-self: center;
}

.proto-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.skills-section {
  background-image: linear-gradient(#24075a, rgb(165, 67, 21));
}
