li {
  list-style-type: none;
}
ul {
  background-color: rgba(0, 0, 0, 0.726);
  padding: 10px;
  border-radius: 5px;
}

a {
  text-decoration: none;
}

#link {
  text-decoration: none;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 490px) {
  h1 {
    color: #000;
  }
}

.header-root-div {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.header-root-div .menu-list {
  display: none;
}

.header-root-div {
  width: 20;
  height: 20;
}

.header-root-div .menu-list {
  display: block;
}
