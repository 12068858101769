.title {
  color: white;
  font-size: 25px;
  font-weight: normal;
  padding-top: 20px;
  text-align: center;
  animation: fadeIn 6s infinite;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

