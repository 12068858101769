@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fasthand&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html{
        font-family: 'Ubuntu', sans-serif;
    }
}
head{
    background-color: rebeccapurple;
}
body{
    margin: 0;
    padding: 0;
}